.c-main{
    height:100vh;
    position:relative;
}

.c-wrapper{
    padding:50px 20px;
    display: flex;
}
.c-left{flex:1}
.form-wrapper{max-width: 600px; margin: 50px auto 0;}
.c-info-item{margin:20px 0 30px;}
.contact-icons a{text-decoration: none; font-size: 60px; color:#60b0f4; padding:0 20px;}
.c-title{font-size: 50px;}
form {
    margin-top:20px;
}
input {
    width:80%;
    height: 40px;
    border:none;
    border-bottom: 1px solid #000;
    margin:10px 0;
    font-size:14px;
}

textarea{
    width:80%;
    margin:10px 0;
    font-size:14px;
}

button{
    border:none;
    padding:15px;
    background-color: #60b0f4;
    color:#fff;
    font-weight:500;
    font-size:18px;
    cursor: pointer;
    border-radius: 4px;
}