.nav-container{width:80%; margin:0 auto;}
.top-bar{
    padding:15px 0;
    display:flex;
    width:100%;
    align-items: center;
}
.top-left{
    flex:1;
    text-align: left;
}
/*.logo-container{width:105px; height: auto;}*/
.top-right{
    flex:1;
    text-align:right;
}

.menu-wrapper{
    background-color: #60b0f4; padding:12px; border-radius: 24px;
    display: inline-block;
}
.menu-btn{display:none; cursor: pointer;}
.menu-wrapper ul{margin:0;}
.menu-items li{
    list-style: none;display:inline-block; 
    margin:0 10px; font-size:20px;
    line-height: normal;
}

.menu-items a{text-decoration: none; color: #444; display: flex; align-items: center; height:24px;}
.menu-items a:hover{text-decoration: underline;}

@media only screen and (max-width: 1199px) {
    .top-left{flex:35%;}
    .top-right{flex:65%;}
}
@media only screen and (max-width: 899px) {
    .top-bar{
        padding:15px 0;
        align-items:flex-start;
    }
    .menu-btn{display:block; font-size:50px; color:#60b0f4;}
    /*.menu-wrapper{border-radius: 5px 5px 24px 24px;}*/
    .menu-wrapper{display:none;}
    .menu-items li{
      font-size:18px;
      display:block;
      margin:0 10px 12px;
    }
    .menu-items{text-align:left;}
    .mobile-menu{
        display:inline-block;
        background-color: #60b0f4;
        padding:15px 20px;
        border-radius: 0 0 10px 10px;
    }
}
