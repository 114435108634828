/***** PAGE CONTAINER *****/
.sect1-container{
    width:80%;
    margin:0 auto;
    height: 90vh;
    position:relative;
    overflow: hidden;
}

/***** HEADLINE AND INTRO TEXT *****/
.page-heading h1{
    margin-top: 60px;
    font-size: 50px;
    font-weight: 900;
}
.blurb{margin-top:30px; font-size:20px;}
.mr-handsome{margin-top:50px;}
.profile-wrapper img{width:240px;}

/***** MID PAGE LINKS *****/
.key-links{
    margin:30px auto;
    text-align: center;
}
.home-link-box{
    display:inline-block;
    margin:0 20px;
}
.home-link-box a{
    text-decoration: none;
    color:#60b0f4;
    font-size:24px;
    font-weight:600;
}
.home-link-box a:hover{color:#444; text-decoration: underline;}
.home-link{
    height:34px;
    display:flex;
    align-items: center;
}


/***** BOTTOM HERO IMAGE *****/
.hero-img{
    position:absolute; 
    bottom:2px; padding:0 35%; 
    line-height: 0%;
}
.hero-img img{
    padding:0; 
    margin:0; 
    display:block;
}
.bottom-bar{
    position:absolute; 
    bottom:0; width:100%; 
    height:15px; 
    background-color: #60b0f4;
}


/***** MEDIA QUERIES *****/
@media only screen and (max-width: 1239px) {
    .hero-img{  padding:0 30%;}
}
@media only screen and (max-width: 999px) {
    .hero-img{ bottom:0; padding:0 25%;}
    .bottom-bar{display:none;}
    .sect1-container{height:90.5vh;}
}
@media only screen and (max-width: 699px) {
    .sect1-container{height:auto;}
    .page-heading h1{margin-top: 30px;font-size: 36px;font-weight: 900; padding:0 5%}
    .blurb{padding:0 5%;}
    .hero-img{ display:none;}
}
@media only screen and (max-width: 599px) {
    .profile-wrapper img{width:180px;}
    .home-link-box a{font-size:20px; font-weight:600;}
}
