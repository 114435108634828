.pl{
    padding:50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.pl-title{
    margin-top: 50px;
    font-size:50px;
    font-weight: 900;
    color:#444;
    padding:0 20px;
}
.pl-desc{
    margin:20px 0 60px;
    font-size:20px; 
    color:#444;
}
.pl-list{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
}

.project-container{
    width:100%; 
    display:flex; 
    flex-wrap: wrap;
}
.project-container h2{
    width:100%;
    color:#6d6e71;
    font-weight:600; 

}
.box-container{
    background-color: #f4f4f4;
    width:100%;
    padding:50px 20px;
    /*box-shadow: 0 8px 6px -6px black;*/
    box-shadow: 0 2px 3px rgba(0,0,0, 0.2);
    border-top:1px solid #eee;
}

.design-projects{
    margin-top:50px;
    width:100%;
}
.shadow{margin-top:20px;}
.link-box{
    width:250px;
    height:320px;
    margin:20px 15px;
    border-radius: 8px;
    overflow: hidden;
    position:relative;
    background-color: #fff;
    border:1px solid #d8d8d9;
}
.link-box p{
    color:#6d6e71;
    font-weight:500;
    font-size: 20px;
}
.link-box a{
    text-decoration: none;
    color:#60b0f4;
    font-size:20px;
}

.pic-view{
    height:50%;
    overflow-y: scroll;
}

.mtext-view{
    height:50%;
    box-sizing: border-box;
    padding-top:20px;
    overflow: hidden;
}
.mtext-view h1{font-size: 32px; margin-bottom:15px;}
.mtext-view p{font-size:16px;}
.blink{margin-top:20px;}
.blink a{text-decoration: none; color:#60b0f4; font-size: 18px;}

@media only screen and (max-width: 699px) {
    .mtext-view{
        height:50%;
        padding-top:20px;
        overflow-y: scroll;
    }
    .mtext-view h1{font-size: 20px; margin-bottom:15px;}
    .mtext-view p{font-size:14px;}
}

.modal-button{cursor: pointer;}
