.itemBox{
    width:280px;
    height:auto;
    margin:20px 15px;
    border-radius: 8px;
    overflow: hidden;
    position:relative;
    /*background-color: #fff;
    border:1px solid #d8d8d9;*/
}
.itemBox a{
    text-decoration: none;
    color:#60b0f4;
}
.itemBox img{display: block;}

.itemBox p{
    color:#fff;
    padding:15px 10px;
}
.box-text{
    position:absolute;
    background-color:rgba(0,0,0, 0.4);
    bottom:0;
    width:100%;
}
.item-disc{
    font-weight:400;
    font-size: 16px;
}

/*.p-browser{
    height:20px; 
    background-color: #eee;
    display:flex;
    align-items: center;
    position:sticky;
    z-index: 2;
}
.p-circle{
    width:6px; height:6px;
    border-radius: 50%;
    background-color:#fff;
    margin:0 3px;
}
.p-img{
    width:100%;
    transition: all 10s ease;
}
.itemBox:hover .p-img{
   transform: translateY(-100%);
}*/